import { all, takeLatest } from "redux-saga/effects";

import { Types as AuthTypes } from '~/store/ducks/auth';
import { Types as NoticesTypes } from '~/store/ducks/notices';
import { Types as NoticeTypes } from '~/store/ducks/notice';
import { Types as VideosTypes } from '~/store/ducks/videos';
import { Types as ClassificationTypes } from '~/store/ducks/classification';
import { Types as RacingNextTypes } from '~/store/ducks/racingNext';
import { Types as RacingTypes } from '~/store/ducks/racing';
import { Types as TeamsTypes } from '~/store/ducks/teams';
import { Types as UserConnectorTypes } from '~/store/ducks/userConnector';
import { Types as UserPurchasesTypes } from '~/store/ducks/userPurchases';
import { Types as NewsRoutesTypes } from '~/store/ducks/newsRoutes';
import { Types as UserTypes } from '~/store/ducks/user';
import { Types as ProductsAvailable } from '~/store/ducks/productsAvailable';
import { Types as PasswordRecoveryTypes } from '~/store/ducks/passwordRecovery';
import { Types as PasswordResetTypes } from '~/store/ducks/passwordReset';
import { Types as UserInfoTypes } from '~/store/ducks/userInfo';
import { Types as ProfileTypes } from '~/store/ducks/profile';
import { Types as LastResultsTypes } from '~/store/ducks/lastResults';
import { Types as LastResultDetailTypes } from '~/store/ducks/lastResultDetail';
import { Types as SeasonsTypes } from '~/store/ducks/seasons';

import { signin, logout } from '~/store/sagas/auth';
import { register } from '~/store/sagas/user';
import { getNotices } from '~/store/sagas/notices';
import { getNotice } from '~/store/sagas/notice';
import { getVideos } from '~/store/sagas/videos';
import { getClassification } from '~/store/sagas/classification';
import { getRacingNext } from '~/store/sagas/racingNext';
import { getRacing } from '~/store/sagas/racing';
import { getTeams } from '~/store/sagas/teams';
import { getUserConnector } from '~/store/sagas/userConnector';
import { getUserPurchases } from '~/store/sagas/userPurchases';
import { getNewsRoutes } from '~/store/sagas/newsRoutes';
import { getProductsAvailable } from '~/store/sagas/productsAvailable';
import { recovery } from '~/store/sagas/passwordRecovery';
import { reset } from '~/store/sagas/passwordReset';
import { getUserInfo } from '~/store/sagas/userInfo';
import { update } from '~/store/sagas/profile';
import { getLastResults } from '~/store/sagas/lastResults';
import { getLastResultDetail } from '~/store/sagas/lastResultDetail';
import { getSeasons } from '~/store/sagas/seasons';

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, signin),
    takeLatest(AuthTypes.LOGOUT_REQUEST, logout),
    takeLatest(UserTypes.REGISTER_REQUEST, register),
    takeLatest(NoticesTypes.GET_REQUEST, getNotices),
    takeLatest(NoticeTypes.GET_REQUEST, getNotice),
    takeLatest(VideosTypes.GET_REQUEST, getVideos),
    takeLatest(ClassificationTypes.GET_REQUEST, getClassification),
    takeLatest(RacingNextTypes.GET_REQUEST, getRacingNext),
    takeLatest(RacingTypes.GET_REQUEST, getRacing),
    takeLatest(TeamsTypes.GET_REQUEST, getTeams),
    takeLatest(UserConnectorTypes.GET_REQUEST, getUserConnector),
    takeLatest(UserPurchasesTypes.GET_REQUEST, getUserPurchases),
    takeLatest(NewsRoutesTypes.GET_REQUEST, getNewsRoutes),
    takeLatest(ProductsAvailable.GET_REQUEST, getProductsAvailable),
    takeLatest(PasswordRecoveryTypes.RECOVERY_REQUEST, recovery),
    takeLatest(PasswordResetTypes.RESET_REQUEST, reset),
    takeLatest(UserInfoTypes.GET_REQUEST, getUserInfo),
    takeLatest(ProfileTypes.UPDATE_REQUEST, update),
    takeLatest(LastResultsTypes.GET_REQUEST, getLastResults),
    takeLatest(LastResultDetailTypes.GET_REQUEST, getLastResultDetail),
    takeLatest(SeasonsTypes.GET_REQUEST, getSeasons),
  ])
}
