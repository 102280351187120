import { put } from 'redux-saga/effects';

import { Creators as LastResultsActions } from '~/store/ducks/lastResults'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getLastResults(action) {
  try {
    const { 
      routeUid, 
      pageSize = 10,
      pageCurrent,
      season
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize,
      page: pageCurrent || 1,
      filter: `(season:${season.selected})`,
    });
    yield put(LastResultsActions.getSuccess({ ...data, isLazy: pageCurrent > 1 }));
  } catch (err) {
    yield put(LastResultsActions.getSuccess({ result: [] }));
  }
}
