import { put } from 'redux-saga/effects';

import { Creators as NoticesActions } from '~/store/ducks/notices'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getNotices(action) {
  try {
    const { 
      routeUid, 
      pageSize = 10,
      pageCurrent
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize,
      page: pageCurrent || 1,
      order_field: 'created_at',
      order_type: 'desc'
    });
    yield put(NoticesActions.getSuccess({ ...data, isLazy: pageCurrent > 1 }));

  } catch (err) {}
}
