// import { authenticationService } from './authentication';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import xApiBuilder from '../analytics/xApiBuilder';
import {
  XApiInterfaceInteraction,
  Sdk,
} from 'api.digitalpages.module.sdk.api';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: 'ded7929c-f1b9-4962-8a7a-b6ce956802fe'
  }
});
appInsights.loadAppInsights();

var Tracker = function () {
  this.normalizeCourse = offer => ({
    ...offer,
    id: offer.id,
    title: offer.offerName,
  });

  this.normalizeTopic = activity => ({
    id: activity.id,
    title: activity.title
  });

  this.sendInterfaceInteraction = function (data) {
    const xApiRequiredProps = ['location', 'name', 'description'];

    if (Sdk.insights.xapi && xApiRequiredProps.every(key => Object.keys(data).includes(key))) {
      let interaction = new XApiInterfaceInteraction(`${data.location}`, "pt-BR", data.name, data.description, `key/${Sdk.Api.projectKey || 'not_found_key'}/interaction/`);
      Sdk.insights.xapi.sendInterfaceInteraction(interaction);
    } else {
      console.warn(`[${data.category}] was not tracked because the data must be passed with: ${JSON.stringify(xApiRequiredProps)}`)
    }

    // var user = authenticationService.getLocalUserData();
    // data.user_id = user.id;
    // data.user_login = user.login;
    // data.user_name = user.name;
    // data.timestamp = String(Math.floor(Date.now() / 1000));

    // appInsights.trackEvent({ "name": data.category }, data);
  }

  this.startTopic = function (offer, activity) {
    const xApiCourse = this.normalizeCourse(offer);
    const xApiTopic = this.normalizeTopic(activity);

    xApiBuilder.send({
      verb: 'started',
      action: 'start',
      metadata: {
        element_id: xApiTopic.id,
        element_title: xApiTopic.title,
      },
      element: 'topic'
    }, xApiCourse, xApiTopic);
  }

  this.finishTopic = function (offer, activity, completed = false, duration) {
    const xApiCourse = this.normalizeCourse(offer);
    const xApiTopic = this.normalizeTopic(activity);

    xApiBuilder.send({
      verb: 'completed',
      action: 'finish',
      metadata: {
        element_id: xApiTopic.id,
        element_title: xApiTopic.title,
        completed,
        duration
      },
      element: 'topic'
    }, xApiCourse, xApiTopic);
  }

  this.sendTrackEvent = function (data, offer, activity) {
    const xApiCourse = this.normalizeCourse(offer);
    const xApiTopic = this.normalizeTopic(activity);

    xApiBuilder.send(data, xApiCourse, xApiTopic);
  }

  return this;
}

var trackerService = new Tracker();

export {
  trackerService
};