import { put } from 'redux-saga/effects';

import { Creators as PasswordRecoveryActions } from '~/store/ducks/passwordRecovery'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* recovery(action) {
  try {
    const {
      email
    } = action.payload || {};

    yield Sdk.Api.directRegister.forgotPassword({ email });
    yield put(PasswordRecoveryActions.recoverySuccess());
  } catch (err) {
    yield put(PasswordRecoveryActions.recoveryFailure());
  }
}
