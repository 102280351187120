import { put } from 'redux-saga/effects';
import { Creators as RacingNextActions } from '~/store/ducks/racingNext'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getRacingNext(action) {
  try {
    const { 
      routeUid, 
      pageSize,
      season,
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize,
      event_status: "Next",
      order_field: "event_at",
      order_type: "asc",
      filter: `(season:${season.selected})`,
    });

    yield put(RacingNextActions.getSuccess(data));
  } catch (err) {}
}
