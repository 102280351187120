import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Sdk from 'api.digitalpages.module.sdk.api';

import "core-js/stable";
import "regenerator-runtime/runtime";
import {getProjectUidByHost} from "~/services/config";

function start() {
  ReactDOM.render(<App/>, document.getElementById('root'));
  Sdk.Api.request.Request.handleError = async function(response) {
    if (response && response.status) {
      const { credential } = Sdk.Api.authorization;

      let currentTimestamp = new Date().getTime()/1000;
      if (credential && !/(2)[0-9][0-9]/g.test(response.status) && credential.expire && currentTimestamp > credential.expire) {
        window.dispatchEvent(new Event("@rdpplayer.event(logout)"));
      }
    }
  };
}

const setActiveProject = async (apiContext) => {
  try {
    // var config = await getProjectUidByHost();

    const projectHostConfig = await getProjectUidByHost();
    let projectUid = null;

    if (projectHostConfig) {
      projectUid = projectHostConfig.project_uid;
    } else {
      const response = await fetch("./api_context.json");
      const config = await response.json();
      projectUid = config.project_uid;
    }

    var projectByDomainRequest = await fetch(`${Sdk.Api.domain}/auth/v1.0/project/domain/${window.location.host}`, {
      "headers": {
        "api-env": Sdk.Api.enviroment,
        "project-key": Sdk.Api.projectKey,
      },
      "method": "GET"
    });
    var projectByDomainResponse = await projectByDomainRequest.json();

    projectUid = projectByDomainResponse.uid;

    var project = await Sdk.dynamic.bridge(`auth/v1.0/project/uid/${projectUid}`);
    await Sdk.initializeFromProject(projectUid, "DirectRegister_1");
    Sdk.authorization.setActiveProject(project);
    Sdk.authorization.activeProjectUid = projectUid;
  } catch (e) {
    console.error(e)
  }
}

async function preStart() {
  const response = await fetch("./api_context.json");
  const config = await response.json();
  // console.log(config)
  Sdk.setProjectKey(config.project_key);
  Sdk.setEnviroment(config.api_env);
  Sdk.authorization.shareSession = true;

  if (config.domain != undefined) {
    Sdk.Api.domain = 'https://' + config.domain;
    //todo:corrigir sdk
    Sdk.Api.authorization.domain = Sdk.Api.domain;
    Sdk.Api.directRegister.domain = Sdk.Api.domain;
    Sdk.Api.storage.domain = Sdk.Api.domain;
    Sdk.Api.store.domain = Sdk.Api.domain;
    Sdk.Api.insights.domain = Sdk.Api.domain;
    Sdk.Api.cms.domain = Sdk.Api.domain;
    Sdk.Api.iot.domain = Sdk.Api.domain;
    Sdk.Api.dynamic.domain = Sdk.Api.domain;
    Sdk.Api.dam.domain = Sdk.Api.domain;
    Sdk.Api.guide.domain = Sdk.Api.domain;
  }

  // await Sdk.initializeFromProject(config.project_uid, "Learning_1");
  await Sdk.initialize({"initializeConnectors": true}, "Learning_1");
  setActiveProject(config);
  start();
}

function onResume() {
  window.dispatchEvent(new Event("@rdpplayer.event(resume.background)"))
}

function startMobile() {
  preStart()
  // TODO: Ativar para usar modo offline
  // Sdk.Api.request.Request.onMobile = true;
  document.addEventListener('resume', onResume, false);
}

document.addEventListener('deviceready', startMobile, false);

if (["http:", "https:"].includes(window.location.protocol) || window.location.hostname === "localhost") {
  preStart();
}

serviceWorker.unregister();
