import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSuccess: ['data'],
  getRequest: []
}, {
  prefix: "@UserInfo/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  data: []
}

const getUserInfoSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
  success: true,
})

const getUserInfoRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUCCESS]: getUserInfoSuccess,
  [Types.GET_REQUEST]: getUserInfoRequest,
})