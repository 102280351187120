/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';

export function useRangeRequest(params) {
   /**
   * RangeElement: {
   *    path: "/test/2",
   *    data: [...],
   *    callbackState: function(state) {}
   * }
   */

  const [context,setContext] = useState({
    waitQueue : [],
    stack : [],
    responses : []
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      clean();
    }
  },[]);

  const clean = ()=> {
    setContext({
      waitQueue : [],
      stack : [],
      responses : []
    });
  }

  const addToQueue = config => 
  {
    if (context.stack.find(p=> p.path == config.path) != null && !config.forceUpdate)
    { 
      context.waitQueue.push(config);
      return;
    }

    if (context.responses.find(p=> p.path == config.path) != null && !config.forceUpdate)
    {
      const reponseref = context.responses.find(p=> p.path == config.path);
      if (reponseref && config.callback) config.callback(reponseref.data);

      return;
    }

    if (config.background == undefined) {
      context.session = +new Date();
      config.session = context.session;
      setLoading(true);
    }
    
    config.forceUpdate = false;
    context.stack.push(config);

    processData(config);
  }

  const processData = async config =>
  {
    let path = config.path;

    Object.entries(params).forEach(([label, value]) => {
      path = path.replace(`:${label}`, value);
    });
    
    config.processing = true;
    const originalData = await Sdk.dynamic.bridge(path)
    let data = originalData;
    config.processing = false;
    
    if (config.type === "array-paginated") 
    {
      data = data.result;
      /**
       * @temp
       * código temporário, deve ser removido quando tivermos comunicação socket com back-end
       */
      if (config.repeat && !data.length) {
        if ("array-paginated is empty") {
          await waitUntil(async () => {
            const response = await Sdk.dynamic.bridge(path);
            data = response.result;
            return data;
          }, data => data && data.length > 0, config.repeat.time, config.repeat.stopWhen)
        }
      }
    }

    config.data = data;
    const configRef = context.stack.find(p=> p.path == config.path);

    if (configRef)
    {
      let index = context.stack.indexOf(configRef);
      if (index > -1) context.stack.splice(index, 1);
    }

    const reponseref = context.responses.find(p=> p.path == config.path);

    if (reponseref)
    {
      let index = context.responses.indexOf(reponseref);
      context.responses.splice(index, 1);
    }

    context.responses.push(config);
    if (config.callback) config.callback(config.data);

    const waiting = [];

    for(var configInQueue of context.waitQueue)
    {
      if (configInQueue.path != config.path) {
        waiting.push(configInQueue);
        continue;
      }

      data = originalData;
      if (configInQueue.type === "array-paginated") data = data.result;

      configInQueue.data = data;
      if (configInQueue.callback) configInQueue.callback(data);
    }

    config.waitQueue = waiting;

    setTimeout(() => {
      if (context.stack.filter(p=> !p.background).length == 0)
      {
        setLoading(false);
      }
    }, 100);
  }

  let waitUntilInterval;
  const waitUntil = async (callback, condition, timeout = 2000, stopWhen = 3) => {
    return await new Promise((resolve, reject) => {
      let repeats = 1;
      waitUntilInterval = setInterval(() => {
        try {
          repeats++;
          callback().then(res => {  

            if (condition(res) || repeats > stopWhen) {
              resolve();
              clearInterval(waitUntilInterval);
            };
          }).catch((e) => {
            reject(e);
            clearInterval(waitUntilInterval);  
          });
        } catch(e) {
          reject(e);
          clearInterval(waitUntilInterval);
        }
      }, timeout);
    });
  }

  return [addToQueue, context, loading, clean];
}


