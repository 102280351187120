import { combineReducers } from "redux";

import * as auth from './auth'
import * as dom from './dom'
import * as filter from './filter'
import * as notices from './notices'
import * as notice from './notice'
import * as videos from './videos'
import * as classification from './classification'
import * as racing from './racing'
import * as racingNext from './racingNext'
import * as teams from './teams'
import * as userConnector from './userConnector'
import * as userPurchases from './userPurchases'
import * as newsRoutes from './newsRoutes'
import * as user from './user'
import * as productsAvailable from './productsAvailable'
import * as passwordRecovery from './passwordRecovery'
import * as passwordReset from './passwordReset'
import * as userInfo from './userInfo'
import * as profile from './profile'
import * as lastResults from './lastResults'
import * as lastResultDetail from './lastResultDetail'
import * as seasons from './seasons'

export const Reducers = {
  auth,
  dom,
  filter,
  notices,
  notice,
  videos,
  classification,
  racing,
  racingNext,
  teams,
  userConnector,
  userPurchases,
  newsRoutes,
  user,
  productsAvailable,
  passwordRecovery,
  passwordReset,
  userInfo,
  profile,
  lastResults,
  lastResultDetail,
  seasons,
};

const ReducerMapping = Object.assign({}, Reducers);

Object
  .keys(ReducerMapping)
  .forEach(key => ReducerMapping[key] = ReducerMapping[key].default);

export default combineReducers(ReducerMapping);
