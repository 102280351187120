import Sdk from 'api.digitalpages.module.sdk.api';
import { put } from 'redux-saga/effects';
import { Creators as NewsRoutesActions } from '~/store/ducks/newsRoutes'; 

export function* getNewsRoutes(action) {
  try {
    const data = yield Sdk.Api.news.getRoutes({all:true});
    yield put(NewsRoutesActions.getSuccess(data));
  } catch (err) {}
}
