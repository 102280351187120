import Customers from "../customers.json";
import Sdk from "api.digitalpages.module.sdk.api";

export const getProjectUidByHost = () => {
	let config = null;

	for (let i = 0; i < Customers.length; i++) {
		if (Customers[i].host === window.location.host) {
			let customer = Customers[i];
			config = {
				domain: customer.domain,
				project_uid: customer.project_uid,
			}
		}
	}

	return config;
}

export const getConfigByProject = async (url) => {
	let config = null;

	try {
		// Ativa cache de configurações
		// let storedConfig = getStoredConfig();
		//
		// if (storedConfig) {
		// 	return storedConfig;
		// }

		const projectHostConfig = getProjectUidByHost();
		let projectUid = null;

		if (projectHostConfig) {
			projectUid = projectHostConfig.project_uid;
		} else {
			const response = await fetch("./api_context.json");
			const config = await response.json();
			projectUid = config.project_uid;
		}

		var projectByDomainRequest = await fetch(`${Sdk.Api.domain}/auth/v1.0/project/domain/${url || window.location.host}`, {
			"headers": {
				"api-env": Sdk.Api.enviroment,
				"project-key": Sdk.Api.projectKey,
			},
			"method": "GET"
		});
		var projectByDomainResponse = await projectByDomainRequest.json();

		projectUid = projectByDomainResponse.uid;

		let projectResp = await fetch(`${Sdk.Api.domain}/auth/v1.0/project/uid/${projectUid}`, {
			"headers": {
				"api-env": Sdk.Api.enviroment,
				"project-key": Sdk.Api.projectKey,
			},
			"method": "GET",
		});

		if (projectResp.status !== 200) {
			throw new Error("Project not found");
		}

		let project = await projectResp.json();

		const connector = project.connectors.find(item => item.type === "Customization_1" && item.scope === "Project");

		if (!connector) {
			throw new Error("Connector not found");
		}

		const connectorUid = connector.uid;
		const response = await Sdk.dynamic.bridge(`auth/v1.1/connector/uid/${connectorUid}`, null, 'GET');

		if (!response) throw new Error("Não foi possível recuperar as configurações do conector");

		const {
			configuration = {}
		} = response;
		const {
			configs = []
		} = configuration;

		config = {};
		config.config = {};

		// Adicione aqui as configurações que deseja utilizar
		const configElementsArr = ["header", "sidebar", "common", "dynamic_recovery_password", "first_access", "login", "course_tree", "users_group", "register"];
		const customerFileConfig = Customers.find(item => item.host === window.location.host);

		configElementsArr.forEach(element => {
			let configData = configs.find(item => item.uid === element);

			// FallBack para as configurações do arquivo de configuração
			if (!configData && customerFileConfig && "config" in customerFileConfig && element in customerFileConfig.config) {
				configData = customerFileConfig.config[element];

				config = {
					config: {
						...config.config,
						[element]: configData
					}
				};
			} else if (configData) {
				let elementConfigs = {};

				JSON.parse(configData.value).forEach(item => {
					elementConfigs[item.uid] = item.value;
				});

				config = {
					config: {
						...config.config,
						[element]: elementConfigs
					}
				};
			}
		});

		const oneHourInMilliseconds = 3600000;
		localStorage.setItem("rdp-app-config", JSON.stringify({
			config,
			expires: new Date().getTime() + oneHourInMilliseconds
		}));
	} catch (e) {
		console.error(e);

		let storedConfig = getStoredConfig({disableExpiration: true});

		// Recupera as configurações do storage
		if (storedConfig) {
			config = storedConfig;
		} else {
			// Recurepa as configurações do arquivo
			for (let i = 0; i < Customers.length; i++) {
				if (Customers[i].host === window.location.host) {
					config = Customers[i];
				}
			}
		}
	}

	return config;
}

const getStoredConfig = (options = {disableExpiration: false}) => {
	let storedConfig = localStorage.getItem("rdp-app-config");

	// Recupera as configurações do storage
	if (storedConfig) {
		let storedConfigParsed = JSON.parse(storedConfig);

		if (options.disableExpiration) {
			return storedConfigParsed.config;
		}

		if (storedConfigParsed.expires > Date.now()) {
			return storedConfigParsed.config;
		}
	}

	return null;
}
