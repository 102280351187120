import { createReducer } from 'reduxsauce'
import { configDuckList } from '~/store/config'

export const { 
  Connector, 
  Route, 
  Types, 
  Creators 
} = configDuckList("@ProductsAvailable");

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  result: []
}

const getProductsAvailableSuccess = (state = INITIAL_STATE, {data:result}) => ({
  ...state,
  result,
  loading: false,
  success: true,
})

const getProducsAvailableRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUCCESS]: getProductsAvailableSuccess,
  [Types.GET_REQUEST]: getProducsAvailableRequest,
})