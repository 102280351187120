import { createReducer, createActions } from 'reduxsauce'

export const Connector = "News_1";

export const { Types, Creators } = createActions({
  getSuccess: ['data'],
  getRequest: ['payload'],
  clearRequest: [],
}, {
  prefix: "@LastResultDetail/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  data: []
}

const getResultDetailRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

const getResultDetailSuccess = (state = INITIAL_STATE, { data : {  data } }) => ({
  ...state,
  data,
  loading: false,
  success: true,
})

const clearRequest = (state = INITIAL_STATE) => ({
  ...state,
  data: [],
  loading: false,
  success: false,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_REQUEST]: getResultDetailRequest,
  [Types.GET_SUCCESS]: getResultDetailSuccess,
  [Types.CLEAR_REQUEST]: clearRequest,
})