import { createReducer } from 'reduxsauce'
import { configDuckList } from '~/store/config'

export const { 
  Connector, 
  Route, 
  Types, 
  Creators 
} = configDuckList("@LastResults", { Connector: "News_1", Route: "results" });

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  result: [],
  page_count: 0,
  current_page: 0
}

const getLastResultsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

const getLastResultsSuccess = (state = INITIAL_STATE, { 
  data : { 
    result, 
    page_count, 
    current_page, 
    isLazy 
  } 
}) => ({
  ...state,
  result: isLazy ? [...state.result, ...result] : result,
  page_count,
  current_page,
  loading: false,
  success: true,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_REQUEST]: getLastResultsRequest,
  [Types.GET_SUCCESS]: getLastResultsSuccess,
})