import { createReducer } from 'reduxsauce'
import { configDuckList } from '~/store/config'

export const { 
  Connector, 
  Route, 
  Types, 
  Creators 
} = configDuckList("@Seasons", { Connector: "News_1", Route: "seasons" }, { setPayload: ['payload'], setCurrent: ['payload'] });

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  result: [],
  selected: null,
  current: null,
}

const getSeasonsRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

const getSeasonsSuccess = (state = INITIAL_STATE, { data : { result } }) => ({
  ...state,
  result,
  loading: false,
  success: true,
})

const setSelected = (state = INITIAL_STATE, action) => ({
  ...state,
  selected: action.payload,
  loading: false,
  success: false
});

const setCurrent = (state = INITIAL_STATE, action) => ({
  ...state,
  current: action.payload,
  loading: false,
  success: false
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_REQUEST]: getSeasonsRequest,
  [Types.GET_SUCCESS]: getSeasonsSuccess,
  [Types.SET_PAYLOAD]: setSelected,
  [Types.SET_CURRENT]: setCurrent,
})