import { put } from 'redux-saga/effects';
import { Creators as SeasonsActions } from '~/store/ducks/seasons'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getSeasons(action) {
  try {
    const { 
      routeUid, 
      pageSize,
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize,
      order_field: "event_at",
      order_type: "asc",
    });

    yield put(SeasonsActions.getSuccess(data));
  } catch (err) {}
}
