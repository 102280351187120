import { createReducer } from 'reduxsauce'
import { configDuckList } from '~/store/config'

export const { 
  Connector, 
  Route, 
  Types, 
  Creators 
} = configDuckList("@Classification", { Connector: "News_1", Route: "racers_teams" });

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  result: []
}

const getClassificationRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

const getClassificationSuccess = (state = INITIAL_STATE, { data : { result } }) => ({
  ...state,
  result,
  loading: false,
  success: true,
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_REQUEST]: getClassificationRequest,
  [Types.GET_SUCCESS]: getClassificationSuccess,
})