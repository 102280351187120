import Sdk from 'api.digitalpages.module.sdk.api';
import { put } from 'redux-saga/effects';
import { Creators as LastResultDetailActions } from '~/store/ducks/lastResultDetail'; 

export function* getLastResultDetail(action) {
  try {
    const { noticeUid } = action.payload || {};
    const data = yield Sdk.Api.news.getNoticeDetails(noticeUid);
    yield put(LastResultDetailActions.getSuccess(data));
  } catch (err) {}
}
