import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  toggleMenuExpansible: null,
  toggleSignatureSuccess: null,
  toggleModalAccount: null,
  toggleHtmlReader: ['payload'],
  toggleFixedModal: ['payload'],
}, {
  prefix: "@DOM"
})

const INITIAL_STATE = {
  menuExpansible: false,
  modalAccount: false,
  htmlReader: { source: null },
  fixedModal: { source: null },
  signatureSuccess: false
}

const toggleMenuExpansible = (state = INITIAL_STATE) => ({
  ...state,
  menuExpansible: !state.menuExpansible
})

const toggleModalAccount = (state = INITIAL_STATE) => ({
  ...state,
  modalAccount: !state.modalAccount
})

const toggleHtmlReader = (state = INITIAL_STATE, { payload : { source, redirect } }) => {
  return ({
    ...state,
    htmlReader: { ...state.htmlReader, source, redirect }
  }) 
}

const toggleFixedModal = (state = INITIAL_STATE, { payload : { source } }) => {
  return ({
    ...state,
    fixedModal: { ...state.fixedModal, source }
  }) 
}
const toggleSignatureSuccess = (state = INITIAL_STATE) => ({
  ...state,
  signatureSuccess: !state.signatureSuccess
})

export default createReducer(INITIAL_STATE, {
  [Types.TOGGLE_MENU_EXPANSIBLE]: toggleMenuExpansible,
  [Types.TOGGLE_MODAL_ACCOUNT]: toggleModalAccount,
  [Types.TOGGLE_HTML_READER]: toggleHtmlReader,
  [Types.TOGGLE_FIXED_MODAL]: toggleFixedModal,
  [Types.TOGGLE_SIGNATURE_SUCCESS]: toggleSignatureSuccess
})