import Sdk from 'api.digitalpages.module.sdk.api';
import { put } from 'redux-saga/effects';
// import { projectUid } from '~/config';

import { Creators as UserConnectorActions } from '~/store/ducks/userConnector'; 

export function* getUserConnector() {
  try {
    const project = Sdk.Api.authorization.activeProject;

    if (project) {
      yield put(UserConnectorActions.getSuccess(project.connectors));
    } 
    // else {
    //   const { connectors } = yield Sdk.Api.authorization.projectInfo(projectUid);
    //   yield put(UserConnectorActions.getSuccess(connectors));
    // }
  } catch (err) {}
}
