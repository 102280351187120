import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  loginRequest: ['payload'],
  loginSuccess: ['username'],
  logoutRequest: null,
  logoutSuccess: null,
  loginFailure: ['error'],
}, {
  prefix: "@Auth/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false
}

const loginRequest = (state = INITIAL_STATE) => ({
  ...state,
  success: false,
  loading: true,
  error: false
})

const loginSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const logoutRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false,
  error: false
})

const logoutSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const loginFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: true
})

export default createReducer(INITIAL_STATE, {
  [Types.LOGIN_REQUEST]: loginRequest,
  [Types.LOGIN_SUCCESS]: loginSuccess,
  [Types.LOGOUT_REQUEST]: logoutRequest,
  [Types.LOGOUT_SUCCESS]: logoutSuccess,
  [Types.LOGIN_FAILURE]: loginFailure
})