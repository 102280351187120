import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  recoveryRequest: ['payload'],
  recoverySuccess: null,
  recoveryFailure: null,
  reset: null,
}, {
  prefix: "@PasswordRecovery/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false
}

const recoveryRequest = (state = INITIAL_STATE) => ({
  ...state,
  success: false,
  loading: true,
  error: false
})

const recoverySuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const recoveryFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: true
})

const reset = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: false
})

export default createReducer(INITIAL_STATE, {
  [Types.RECOVERY_REQUEST]: recoveryRequest,
  [Types.RECOVERY_SUCCESS]: recoverySuccess,
  [Types.RECOVERY_FAILURE]: recoveryFailure,
  [Types.RESET]: reset,
})