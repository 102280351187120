import Sdk from 'api.digitalpages.module.sdk.api';
import { put } from 'redux-saga/effects';
import { Creators as NoticeActions } from '~/store/ducks/notice'; 

export function* getNotice(action) {
  try {
    const { noticeUid } = action.payload || {};
    const data = yield Sdk.Api.news.getNoticeDetails(noticeUid);
    yield put(NoticeActions.getSuccess(data));
  } catch (err) {}
}
