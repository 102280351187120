import { put } from 'redux-saga/effects';
import { Creators as ProfileActions } from '~/store/ducks/profile'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* update(action) {
  try {
    const {
      name: first_name,
      lastName: last_name,
      email_principal,
      cpf,
      birthday,
      region,
      voucher
    } = action.payload || {};

    if (voucher) {
      yield Sdk.Api.store.purchaseVoucher(voucher);
    }
    
    const body = { 
      detail: {
        name: `${first_name} ${last_name}`,
        email_principal, 
        first_name, 
        last_name,
        cpf,
        birthday,
        region
      }
    };

    yield Sdk.Api.authorization.directRegister.update(body);
    yield Sdk.Api.authorization.updateUserInfoAndPermissions();
    yield put(ProfileActions.updateSuccess());
  } catch (err) {
    yield put(ProfileActions.updateFailure());
  }
}
