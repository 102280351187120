import { put } from 'redux-saga/effects';
import { Creators as ProductsAvailable } from '~/store/ducks/productsAvailable'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getProductsAvailable() {
  try {
    const data = yield Sdk.Api.store.getProductsAvailable();
    yield put(ProductsAvailable.getSuccess(data));
  } catch (err) {}
}
