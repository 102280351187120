/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useState} from 'react';
import {Redirect, Route, useHistory, useParams} from 'react-router-dom';

import Components from 'rdp-builder-components';
import ReactTooltip from 'react-tooltip';

import Sdk from 'api.digitalpages.module.sdk.api';

import {dispatch as $dispatch} from '~/events';
import ConfigPages from '~/config-pages.json';
import preferences from '~/preferences.json';
import {useAuth} from '~/hooks/auth';
import {Flows} from '~/api/flows';
import {useRangeRequest} from '~/hooks/range-request';
import {trackerService} from '~/analytics/tracker';
import {getConfigByProject} from "~/services/config";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {AppContext} from "~/contexts/app-context";
import User from "./events/user"
import { OptimisticDataWrapper } from "ecc-data-fetch-sdk";

function WrapperProvider({
  props,
  configProps,
  wrapper,
  showReCaptchaBadge,
  context,
  config,
  setConfig,
  executeRecaptcha,
}) {
  const history = useHistory();
  const params = useParams();
  const [logged, setLogged] = useAuth();
  const [addToQueue, contextData, loading, clean] = useRangeRequest(params);
  const [promises, setPromises] = useState([])
  const [componentsData, setComponentsData] = useState({});
  const [lastUpdate, setLastUpdate] = useState();
  const appConfig = config;

  useEffect(() => {
    window.RDP_PREFERENCES = preferences;
    document.getElementsByTagName("body")[0].classList.add(preferences.font_family);
    document.getElementById("rdp-favicon").href = preferences.favicon;
    var force_https = preferences.force_https;
    if (force_https) {
      for (var i = 0; i < force_https.length; i++) {
        if (window.location.host === force_https[i] && window.location.protocol === "http:") {
          window.location.protocol = "https:";
        }
      }
    }

    // clean();

    window.addEventListener("@rdpplayer.event(logout)", async function () {
      if (logged) setLogged(false);
      // Sdk.Api.authorization.logout();
      // window.location = window.location.origin + "#/";
    });

    registerCourse();

    if ("toggleReCaptchaBadge" in context) {
      context.toggleReCaptchaBadge(!!showReCaptchaBadge);
    }

    User.checkPendingSubscriptions();

    return function () {
      setComponentsData({});
    }

  }, []);

  let url = new URL(window.location.origin + "?" + (window.location.href.split("?")[1] || ""))
  if (url.searchParams.has("subscribe")) {
    window.localStorage.setItem(window.location.origin + "RDP_COURSE_SUBSCRIBE", url.searchParams.get("subscribe"))
  } else if (url.searchParams.has("multiple-subscribe")) {
    window.localStorage.setItem(window.location.origin + "RDP_COURSE_SUBSCRIBE", url.searchParams.get("multiple-subscribe"))
  }

  const registerCourse = async () => {
    // if (Sdk.Api.authorization.user !== null) {
    //   var course = await Sdk.dynamic.bridge(`auth/v1.0/user/me/preference/key/course_subscribe`);
    //   var registers = await Sdk.dynamic.bridge(`cms/v1.0/learning/course/uid/${course.value}/registers`);
    //
    //   await Sdk.dynamic.bridge(`cms/v1.0/learning/register/uid/${registers.result[0].uid}`, null, "POST").then(function (data) {
    //     window.dataLayer.push({
    //       'event': "clickEvent",
    //       'eventCategory': `${(preferences.google_gtm && preferences.google_gtm.client) ? preferences.google_gtm.client : "digital-pages"}:funil-de-compra`,
    //       'eventAction': "botao:click",
    //       'eventLabel': "cadastre-se",
    //       'step': 'step-3',
    //       'userID': Sdk.authorization.user.uid,
    //       'ecommerce': {
    //         'add': {
    //           'products': [{
    //             'category': 'prospect',
    //             'name': data.course.title,
    //             'id': data.course.uid,
    //             'price': 1900
    //           }]
    //         }
    //       },
    //       'eventCallback': (id) => {
    //         window.dataLayer.push({
    //           'event': "clickEvent",
    //           'eventCategory': `${(preferences.google_gtm && preferences.google_gtm.client) ? preferences.google_gtm.client : "digital-pages"}:funil-de-compra`,
    //           'eventAction': "botao:click",
    //           'eventLabel': "compra",
    //           'step': 'step-4',
    //           'userID': Sdk.authorization.user.uid,
    //           'ecommerce': {
    //             'add': {
    //               'products': [{
    //                 'category': 'lead',
    //                 'name': data.course.title,
    //                 'id': data.course.uid,
    //                 'price': 1900
    //               }]
    //             }
    //           },
    //           'eventCallback': (uid) => {
    //             window.location = window.location.href.split("?subscribe=")[0];
    //           }
    //         });
    //       }
    //     });
    //
    //
    //   });
    //
    //   await Sdk.dynamic.bridge(`auth/v1.0/user/me/preference/uid/${course.uid}`, null, "DELETE");
    //
    //   configProps.children.map(config => {
    //     handleRequest(config);
    //   });
    // }
  }

  const trackEvent = (data) => {
    trackerService.sendInterfaceInteraction(data);
  }

  const handleExecuteRecaptcha = async (action = "") => {
    try {
      // O reCaptcha só aceita a "action" no formato do regex abaixo
      return await executeRecaptcha(action.replace(/[^a-zA-Z]+/g, ''));
    } catch (error) {
      console.error(error);
    }
  }

  const dispatch = (action, payload, onChangeState) => {
    return $dispatch({
        action,
        payload,
        onChangeState,
        setConfig,
        executeRecaptcha: async (customAction) => handleExecuteRecaptcha(customAction || action),
      }, {history}
    );
  }

  const executeAction = (action, context, element, params) => {
    return eval(action)
  }

  const processApiResult = (api, context) => {
    const {path: pathConfig, map, eval: evalData, field: fieldConfig} = api;
    const response = contextData.responses.find(({path}) => path == pathConfig);

    if (!response) {
      return false;
    }

    var mapData = response.data;

    var extractData = function (map, context, element) {
      const v = {};
      Object.entries(map).forEach(([to, from]) => {
        v[to] = eval(`${from}`);
      });

      return v;
    }

    if (map) {
      if (Array.isArray(response.data)) {
        mapData = response.data.map(element => {
          return extractData(map, context, element);
        });
      } else {
        mapData = extractData(map, context, response.data);
      }
    }

    if (evalData) {
      mapData = executeAction(evalData, context, response.data, params);
    }

    context[fieldConfig] = mapData;
    return true;
  }

  const wrapperBridge = (flow) => {
    if (flow.bridges === undefined) flow.bridges = {};

    return function (path, forceUpdate) {
      const field = btoa(path);
      const data = {path: path, type: "object", field: field, forceUpdate: forceUpdate, background: true};

      return new Promise(function (resolse, reject) {
        const response = contextData.responses.find(p => p.path == path);
        if (response && !forceUpdate) {
          resolse(response.data);
        } else {
          data.callback = function (responseData) {
            resolse(responseData);
          }
          addToQueue(data)
        }
      });
    }
  }

  const flowExecute = (flow, contextFinal) => {
    switch (flow.type) {
      case "api": {
        if (processApiResult(flow.data, contextFinal)) {
          flow.processing = false;
          contextFinal.flowProcessed[flow.__id] = true;
        } else if (!flow.processing) {
          if (flow.data.eval_path) {
            flow.data.path = executeAction(flow.data.eval_path, contextFinal, null, params);
          }

          flow.processing = true;
          addToQueue(flow.data);
        }
        break;
      }
      case "inline": {
        contextFinal[flow.data.field] = executeAction(flow.data.eval, contextFinal, null, params);
        contextFinal.flowProcessed[flow.__id] = true;
        break;
      }
      case "pure": {
        const result = Flows()[flow.data.flow](props.match.params, wrapperBridge(flow), contextFinal, config, flow, context);
        if (flow.data.field && result) contextFinal[flow.data.field] = result;
        break;
      }
      case "promise": {
        let info = promises.find(p => p.name == flow.data.flow);

        if (info == null) {
          var newPromise = async function () {
            var result = await Flows()[flow.data.flow](props.match.params, wrapperBridge(flow), contextFinal, config, flow, context);
            contextFinal.flowProcessed[flow.__id] = true;

            if (flow.data.field && result) contextFinal[flow.data.field] = result;

            // console.log("context", contextFinal);
            setLastUpdate(Date.now());
            return result;
          }

          info = {
            "name": flow.data.flow,
            "promise": newPromise()
          }

          promises.push(info);
        }

        //todo: retirar
        contextFinal.resetPromise = function () {
          contextFinal.promise = newPromise()
          return contextFinal.promise;
        };

        //todo: retirar
        contextFinal.promise = info.promise.then(p => {
          if (flow.data.flowReloadInterval) {
            setTimeout(() => {

              let newList = [...promises];
              let index = newList.indexOf(info);

              if (index != -1) newList.splice(index, 1);

              setPromises(newList);

            }, flow.data.flowReloadInterval);
          }

          return p;
        });

        break;
      }
    }
  }

  const createChild = config => {
    if (!config) return null;
    if (config.__id == undefined) {
      config.__id = `${Math.random().toString(36).substr(2, 9)}`;
    }

    if (config.api) {
      var flows = config.api.map(p => ({type: "api", data: p}));
      if (!config.flows) config.flows = [];

      config.flows = flows.concat(config.flows);
      delete config.api;
    }

    if (config.flows == undefined) config.flows = [];

    if (config.promise !== undefined) {

      config.flows.push({
        type: "promise",
        data: {
          flow: config.promise.flow,
          flowReloadInterval: config.promise.reloadInterval,
          params: props.match.params,
          flow_props: config.promise.flow_props
        }
      });

      delete config.promise;
    }

    let flowResult = componentsData[config.__id];

    if (flowResult == undefined) {
      flowResult = {flowProcessed: {}};
      componentsData[config.__id] = flowResult;
    }

    var reprocess = config.params !== params;
    config.params = params;

    if (config.flows !== undefined) {
      for (const index in config.flows) {
        const flow = config.flows[index];

        if (flow.__id == undefined) flow.__id = `${Math.random().toString(36).substr(2, 9)}`;
        if (reprocess) flowResult.flowProcessed[flow.__id] = false;
        if (flowResult.flowProcessed[flow.__id]) continue;

        if (flow.data.required) {
          var pass = true;

          flow.data.required.map(field => {
            if (!flowResult[field]) pass = false
          });

          if (!pass) continue;
        }

        try {
          flowExecute(flow, flowResult);
        } catch (e) {
          throw e;
        }
      }
    }

    let optimisticFlows = config.flows.filter(p => p.type === "optimistic") || [];
    optimisticFlows = optimisticFlows.map(f => f.data)

    if (optimisticFlows.length > 0) {
      return React.createElement(OptimisticDataWrapper, {
        key: config.__id,
        requestInfos: optimisticFlows || [],
        flows: Flows(),
        children: Components[config.library][config.component],
        childrenProps: {
          ...config.props,
          // ...apiProps,
          ...flowResult,
          loading,
          apiContext: Sdk.Api,
          online: context.online || true,
          routeParams: params,
          key: config.__id,
          dispatch,
          trackEvent
        }
      });
    } else {
      return React.createElement(Components[config.library][config.component], {
        ...config.props,
        // ...apiProps,
        ...flowResult,
        loading,
        appConfig,
        apiContext: Sdk.Api,
        online: context.online || true,
        routeParams: params,
        key: config.__id,
        dispatch,
        trackEvent
      });
    }
  }

  const handleRequest = config => {
    if (config && config.api) {
      config.api.forEach(addToQueue)
    }
  }

  const {credential} = Sdk.Api.authorization;

  let currentTimestamp = new Date().getTime() / 1000;
  if (credential && credential.expire && currentTimestamp > credential.expire && !configProps.anonymous_viewer) {
    window.dispatchEvent(new Event("@rdpplayer.event(logout)"));
  }

  if (configProps.documentTitle) {
    document.title = configProps.documentTitle;
  }

  if (logged && configProps.anonymous_viewer) {
    let hasCatalogMenu = false;

     try {
       if (config && "config" in config) {
         const configData = config.config;

         if (configData && "users_group" in configData) {
           const {
             enabled = false,
           } = configData.users_group;

           hasCatalogMenu = enabled;
         }
       }
     } catch (e) {
       console.error(e);
     }

    const isOnCatalog = window.location.href.includes("/#/catalogo");

    if (isOnCatalog && !hasCatalogMenu) {
      return <Redirect to="/inicio"/>
    } else {
      return <Redirect to="/catalogo"/>
    }
  }

  if (!logged && configProps.anonymous_viewer === false) {
    // window.localStorage.setItem(window.location.origin + "RDP_FORCE_REDIRECT", window.location.hash)
    const isOnCordova = window.cordova || window.location.hostname === "localhost";
    return <Redirect to={isOnCordova ? "/mobile-login" : "/"}/>
  }

  return (
    <>
      <ReactTooltip/>
      {
        React.createElement(Components["Wrappers"][wrapper], {
          loading,
          ...props,
          ...configProps,
          ...configProps.props,
          $header: createChild(configProps.$header),
          $sidebar: createChild(configProps.$sidebar),
          $bottomNavigation: createChild(configProps.$bottomNavigation),
          $terms: createChild(configProps.$terms),
          $toast: createChild(configProps.$toast),
          children: configProps.children.map(createChild),
        })
      }
    </>
  )
}

export default function Routes() {
  const [Routes, setRoutes] = useState([]);
  const [logged] = useAuth();
  const [config, setConfig] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const pageIsEnabled = (page) => {
    const project = Sdk.Api.authorization.activeProject || null;

    if (project) {
      if (page.path === "/cadastro") {
        const directRegisterConnector = project.connectors.find(connector => connector.type === "DirectRegister_1");

        return undefined !== directRegisterConnector;
      }
    }

    return true;
  }

  const buildConfiguration = useCallback( () => {
    var pages = ConfigPages;

    if (config && "config" in config) {
      var customHeaderProps = config.config.header || {};
      var customSidebarProps = config.config.sidebar || {};
      var customCommonProps = config.config.common || {};

      for (var i = 0; i < pages.length; i++) {
        if (pages[i]["$header"]) {
          var headerProps = pages[i]["$header"].props;

          if (headerProps.background) headerProps.background = customHeaderProps.background;
          if (headerProps.buttonsBackground) headerProps.buttonsBackground = customHeaderProps.buttons_background;
          if (headerProps.buttonsIconBackground) headerProps.buttonsIconBackground = customHeaderProps.buttons_icon_background;
          if (headerProps.buttonsTextColor) headerProps.buttonsTextColor = customHeaderProps.buttons_text_color;
          if (headerProps.dropdownBackground) headerProps.dropdownBackground = customHeaderProps.dropdown_background;
          if (headerProps.dropdownButtonBackground) headerProps.dropdownButtonBackground = customHeaderProps.dropdown_button_background;
          if (headerProps.dropdownButtonIconBackground) headerProps.dropdownButtonIconBackground = customHeaderProps.dropdown_button_icon_background;
          if (headerProps.logotype) headerProps.logotype = customHeaderProps.logotype;
          if (headerProps.notificationsHeaderColor) headerProps.notificationsHeaderColor = customHeaderProps.notifications_header_color;
          if (headerProps.firstLabelColor) headerProps.firstLabelColor = window.innerWidth <= 599 ? customSidebarProps.label_color : customHeaderProps.first_label_color;
          if (headerProps.secondLabelColor) headerProps.secondLabelColor = window.innerWidth <= 599 ? customSidebarProps.label_color : customHeaderProps.second_label_color;

          // Header Drawer
          if (headerProps.backgroundColor) {
            headerProps.backgroundColor = customCommonProps.primary_color;
            headerProps.iconColorTag = customCommonProps.primary_color;
          }
          if (headerProps.itemBorderColor) headerProps.itemBorderColor = customCommonProps.primary_color;
          if (headerProps.activeButtonBackgroundColor) headerProps.activeButtonBackgroundColor = customCommonProps.tertiary_color;
          if (headerProps.labelColor) headerProps.labelColor = customCommonProps.secondary_color;
          if (headerProps.activeLabelColor) headerProps.activeLabelColor = customCommonProps.secondary_color;
          if (headerProps.iconColor) headerProps.iconColor = customCommonProps.secondary_color;
          if (headerProps.activeIconColor) headerProps.activeIconColor = customCommonProps.secondary_color;
          if (headerProps.buttonBackgroundColor) headerProps.buttonBackgroundColor = customCommonProps.primary_color;
        }

        if (pages[i]["$sidebar"]) {
          var sidebarProps = pages[i]["$sidebar"].props;

          if (sidebarProps.activeButtonBackgroundColor) sidebarProps.activeButtonBackgroundColor = customSidebarProps.active_button_background_color;
          if (sidebarProps.activeIconColor) sidebarProps.activeIconColor = customSidebarProps.active_icon_color;
          if (sidebarProps.activeLabelColor) sidebarProps.activeLabelColor = customSidebarProps.active_label_color;
          if (sidebarProps.backgroundColor) sidebarProps.backgroundColor = customSidebarProps.background_color;
          if (sidebarProps.buttonBackgroundColor) sidebarProps.buttonBackgroundColor = customSidebarProps.button_background_color;
          if (sidebarProps.iconColor) sidebarProps.iconColor = customSidebarProps.icon_color;
          if (sidebarProps.itemBorderColor) sidebarProps.itemBorderColor = customSidebarProps.item_border_color;
          if (sidebarProps.labelColor) sidebarProps.labelColor = customSidebarProps.label_color;
        }

        if (pages[i]["$bottomNavigation"]) {
          var bottomNavigationProps = pages[i]["$bottomNavigation"].props;

          if (bottomNavigationProps.activeButtonBackgroundColor) bottomNavigationProps.activeButtonBackgroundColor = customCommonProps.tertiary_color;
          if (bottomNavigationProps.activeIconColor) bottomNavigationProps.activeIconColor = customCommonProps.secondary_color;
          if (bottomNavigationProps.activeLabelColor) bottomNavigationProps.activeLabelColor = customCommonProps.secondary_color;
          if (bottomNavigationProps.backgroundColor) bottomNavigationProps.backgroundColor = customCommonProps.primary_color;
          if (bottomNavigationProps.buttonBackgroundColor) bottomNavigationProps.buttonBackgroundColor = customCommonProps.primary_color;
          if (bottomNavigationProps.iconColor) bottomNavigationProps.iconColor = customCommonProps.secondary_color;
          if (bottomNavigationProps.itemBorderColor) bottomNavigationProps.itemBorderColor = customCommonProps.primary_color;
          if (bottomNavigationProps.labelColor) bottomNavigationProps.labelColor = customCommonProps.secondary_color;
        }

        var terms = pages[i]["$terms"] || null;

        if (terms) {
          var termsProps = terms.props;
          if (termsProps.titleColor) termsProps.titleColor = customCommonProps.secondary_color;
          if (termsProps.acceptButtonBGColor) termsProps.acceptButtonBGColor = customCommonProps.secondary_color;
          if (termsProps.acceptButtonColor) termsProps.acceptButtonColor = customCommonProps.primary_color;
          if (termsProps.declineButtonBGColor) termsProps.declineButtonBGColor = customCommonProps.secondary_color;
          if (termsProps.declineButtonColor) termsProps.declineButtonColor = customCommonProps.primary_color;
        }

        if (pages[i].props && pages[i].props.background) {
          pages[i].props.background = customCommonProps.apresentation_background;
        }

        var children = pages[i].children;
        for (var j = 0; j < children.length; j++) {
          var properties = children[j].props;

          if (properties) {
            // TreeScoreTable
            if (properties.styles && properties.styles.header) {
              if (properties.styles.header.backgroundColor) properties.styles.header.backgroundColor = customCommonProps.primary_color;
              if (properties.styles.header.color) properties.styles.header.color = customCommonProps.secondary_color;
            }

            // PLAYER
            if (properties.headerBackground) properties.headerBackground = customCommonProps.secondary_color;
            if (properties.headerIconColor) properties.headerIconColor = customCommonProps.primary_color;
            if (properties.headerTitleColor) properties.headerTitleColor = customCommonProps.primary_color;
            if (properties.navigationBtnBackgroundColor) properties.navigationBtnBackgroundColor = customCommonProps.primary_color;
            if (properties.navigationBtnLabelColor) properties.navigationBtnLabelColor = customCommonProps.secondary_color;
            if (properties.sidebarBackgroundColor) properties.sidebarBackgroundColor = customCommonProps.primary_color;
            if (properties.sidebarBtnActiveBackgroundColor) properties.sidebarBtnActiveBackgroundColor = customCommonProps.secondary_color;
            if (properties.sidebarBtnActiveIconColor) properties.sidebarBtnActiveIconColor = customCommonProps.tertiary_color;
            if (properties.sidebarBtnActiveTextColor) properties.sidebarBtnActiveTextColor = customCommonProps.primary_color;
            if (properties.sidebarBtnBackgroundColor) properties.sidebarBtnBackgroundColor = customCommonProps.primary_color;
            if (properties.sidebarBtnBorderColor) properties.sidebarBtnBorderColor = customCommonProps.primary_color;
            if (properties.sidebarBtnIconColor) properties.sidebarBtnIconColor = customCommonProps.fourthiary_color;
            if (properties.sidebarBtnTextColor) properties.sidebarBtnTextColor = customCommonProps.fourthiary_color;
            if (properties.sidebarHeaderBackBtnIconColor) properties.sidebarHeaderBackBtnIconColor = customCommonProps.primary_color;
            if (properties.sidebarHeaderBackground) properties.sidebarHeaderBackground = customCommonProps.secondary_color;
            if (properties.sidebarHeaderDescriptionColor) properties.sidebarHeaderDescriptionColor = customCommonProps.primary_color;
            if (properties.sidebarHeaderTitleBackground) properties.sidebarHeaderTitleBackground = customCommonProps.secondary_color;
            if (properties.sidebarHeaderTitleColor) properties.sidebarHeaderTitleColor = customCommonProps.primary_color;

            if (properties.iconColor) properties.iconColor = customCommonProps.secondary_color;
            if (properties.noContentIconColor) properties.noContentIconColor = customCommonProps.secondary_color;
            if (properties.noContentMessageColor) properties.noContentMessageColor = customCommonProps.secondary_color;

            if (properties.colorBorder) properties.colorBorder = customCommonProps.secondary_color;
            if (properties.progressBackgroundColor) properties.progressBackgroundColor = customCommonProps.secondary_color;
            if (properties.progressLabelColor) properties.progressLabelColor = customCommonProps.primary_color;

            if (properties.buttonColor) properties.buttonColor = customCommonProps.secondary_color;
            if (properties.buttonFontColor) properties.buttonFontColor = customCommonProps.primary_color;

            if (properties.iconBackgroundColor) properties.iconBackgroundColor = customCommonProps.tertiary_color;

            //TABS
            if (properties.colorItem) properties.colorItem = customCommonProps.primary_color;
            if (properties.backGroundItem) properties.backGroundItem = customCommonProps.secondary_color;

            // TELA DE LOGIN
            if (properties.titleColor) properties.titleColor = customCommonProps.secondary_color;
            if (properties.backGroundColor) properties.backGroundColor = customCommonProps.tertiary_color;
            if (properties.fontColor) properties.fontColor = customCommonProps.secondary_color;
            if (properties.recoverPasswordColor) properties.recoverPasswordColor = customCommonProps.secondary_color;
            if (properties.firstAccessColorButton) properties.firstAccessColorButton = customCommonProps.secondary_color;
            if (properties.firstAccessFontColorButton) properties.firstAccessFontColorButton = customCommonProps.secondary_color;
            if (properties.colorIcon) properties.colorIcon = customCommonProps.secondary_color;
            if (properties.registerTitleColor) properties.registerTitleColor = customCommonProps.secondary_color;
            if (properties.registerFontColorButton) properties.registerFontColorButton = customCommonProps.primary_color;
            if (properties.registerColorButton) properties.registerColorButton = customCommonProps.secondary_color;

            // TELA DE CADASTRO
            if (properties.signinButtonColor) properties.signinButtonColor = customCommonProps.secondary_color;
            if (properties.signinButtonBgColor) properties.signinButtonBgColor = customCommonProps.primary_color;
            if (properties.registerButtonColor) properties.registerButtonColor = customCommonProps.secondary_color;
            if (properties.registerButtonBgColor) properties.registerButtonBgColor = customCommonProps.tertiary_color;

            // TELA DE CATÁLOGO
            if (properties.secondaryColor) properties.secondaryColor = customCommonProps.secondary_color;

            // TELA DE RECUPERAR SENHA
            if (properties.sendButtonBgColor) properties.sendButtonBgColor = customCommonProps.tertiary_color;
            if (properties.sendButtonColor) properties.sendButtonColor = customCommonProps.secondary_color;
            if (properties.backButtonBgColor) properties.backButtonBgColor = customCommonProps.secondary_color;
            if (properties.backButtonColor) properties.backButtonColor = customCommonProps.primary_color;
            if (properties.firstAccessColorButton) properties.firstAccessColorButton = customCommonProps.tertiary_color;
            if (properties.firstAccessFontColorButton) properties.firstAccessFontColorButton = customCommonProps.secondary_color;

            if (properties.schema) {
              var schemas = properties.schema;

              for (var k = 0; k < schemas.length; k++) {
                var schema = schemas[k];

                if (schema.background_button_arrow) schema.background_button_arrow = customCommonProps.secondary_color;
                if (schema.color_arrow) schema.color_arrow = customCommonProps.primary_color;
                if (schema.enabled_icon_background_color) schema.enabled_icon_background_color = customCommonProps.tertiary_color;
                if (schema.enabled_icon_color) schema.enabled_icon_color = customCommonProps.secondary_color;
              }
            }

            // Quadro de avisos
            if (properties.primaryColor) properties.primaryColor = customCommonProps.secondary_color;
            if (properties.backgroundColor) properties.backgroundColor = customCommonProps.primary_color;

            // if (properties.colorTitile)

            if (properties.child) {
              var childProps = properties.child.props;

              if (childProps) {
                if (childProps.colorButton) childProps.colorButton = customCommonProps.secondary_color;
                if (childProps.colorFontButton) childProps.colorFontButton = customCommonProps.primary_color;
                if (childProps.colorTitle) childProps.colorTitle = customCommonProps.secondary_color;
                if (childProps.dateIconColor) childProps.dateIconColor = customCommonProps.tertiary_color;
              }
            }
          }
        }
      }
    }

    setRoutes(
      pages.map((page) => {
        const {
          exact,
          path,
          wrapper,
          showReCaptchaBadge,
          ...configProps
        } = page;

        if (config && !pageIsEnabled(page)) return null;

        return (
          <Route
            key={path}
            exact={exact}
            path={path}
            render={props => (
              <AppContext.Consumer>
                {(appContext) => (
                  <WrapperProvider
                    key={path}
                    logged={logged}
                    props={props}
                    configProps={configProps}
                    setConfig={setConfig}
                    config={config}
                    wrapper={wrapper}
                    showReCaptchaBadge={showReCaptchaBadge}
                    context={appContext}
                    executeRecaptcha={executeRecaptcha}
                  />
                )}
              </AppContext.Consumer>
            )}
          />
        )
      })
    )
  }, [logged, config])

  useEffect( () => {
    if (config === null) {
      async function fetchConfig() {
        const conf = await getConfigByProject();
        setConfig(conf);
      }
      fetchConfig().then(() => buildConfiguration());
    } else {
      buildConfiguration();
    }
  }, [logged, config]);

  return Routes;
}
