import { createReducer } from 'reduxsauce'
import { configDuckList } from '~/store/config'

export const { 
  Connector, 
  Route, 
  Types, 
  Creators 
} = configDuckList("@NewsRoutes", { Connector: "News_1" });

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  data: []
}

const getNewsRoutesSuccess = (state = INITIAL_STATE, { data : { result } }) => ({
  ...state,
  data: result,
  loading: false,
  success: true,
})

const getNewsRoutesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUCCESS]: getNewsRoutesSuccess,
  [Types.GET_REQUEST]: getNewsRoutesRequest,
})