import { put } from 'redux-saga/effects';

import { Creators as ClassificationActions } from '~/store/ducks/classification'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getClassification(action) {
  try {
    const { 
      routeUid, 
      pageSize,
      season
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize: pageSize || 40,
      filter: `(season:${season.selected})`,
    });

    yield put(ClassificationActions.getSuccess(data));
  } catch (err) {}
}
