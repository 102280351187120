import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  setActiveFilter: ['page', 'identifier']
}, {
  prefix: "@Filter"
})

const INITIAL_STATE = {
  activeLink: {},
}

const setActiveFilter = (state = INITIAL_STATE, action) => ({
  ...state,
  activeLink: {
    ...state.activeLink,
    [action.page]: action.identifier
  }
})

export default createReducer(INITIAL_STATE, {
  [Types.SET_ACTIVE_FILTER]: setActiveFilter
})