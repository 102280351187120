const getConnector = (state, connectorType) => 
  state.userConnector.data.find(connector => connector.type === connectorType)

const getRoute = (state, routeIdentifier) => 
  state.newsRoutes.data.find(route => route.identifier === routeIdentifier)

export default ({ getState }) => next => async action => {
  if (action.payload && action.payload.connector) {
    const { connector, route } = action.payload;
    const state = getState();

    if (connector && !action.type.includes("@UserConnector")) {
      const foundConnector = getConnector(state, connector);
      if (foundConnector) {
        action.payload.connectorUid = foundConnector.uid
      }
    }

    if (route && !action.type.includes("@NewsRoutes")) {
      const foundRoute = getRoute(state, route);
      if (foundRoute) {
        action.payload.routeUid = foundRoute.uid
      }
    }
  }
  
  return next(action);
};
