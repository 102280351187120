import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  registerRequest: ['payload'],
  registerSuccess: null,
  registerFailure: ['message'],
  reset: null,
}, {
  prefix: "@User/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false
}

const registerRequest = (state = INITIAL_STATE) => ({
  ...state,
  success: false,
  loading: true,
  error: false
})

const registerSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const registerFailure = (state = INITIAL_STATE, action) => ({
  ...state,
  loading: false,
  success: false,
  error: true,
  message: action.message
})

const reset = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: false
})

export default createReducer(INITIAL_STATE, {
  [Types.REGISTER_REQUEST]: registerRequest,
  [Types.REGISTER_SUCCESS]: registerSuccess,
  [Types.REGISTER_FAILURE]: registerFailure,
  [Types.RESET]: reset,
})