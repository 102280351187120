import { put } from 'redux-saga/effects';

import { Creators as PasswordResetActions } from '~/store/ducks/passwordReset'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* reset(action) {
  try {
    const {
      token,
      password
    } = action.payload || {};

    yield Sdk.Api.directRegister.resetPassword({ token, password });
    yield put(PasswordResetActions.resetSuccess());
  } catch (err) {
    console.log(err)
    yield put(PasswordResetActions.resetFailure());
  }
}
