import { put } from 'redux-saga/effects';
import { Creators as UserPurchasesActions } from '~/store/ducks/userPurchases'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getUserPurchases() {
  try {
    const data = yield Sdk.Api.store.getPurchasesAvailable();
    yield put(UserPurchasesActions.getSuccess(data));
  } catch (err) {}
}
