import createSagaMiddleware from 'redux-saga';

import { createStore, compose, applyMiddleware } from "redux";

import rootReducer from './ducks';
import rootSaga from './sagas';

import connectorMiddleware from './middlewares/connector';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, connectorMiddleware];

const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

export { store };