import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  updateRequest: ['payload'],
  updateSuccess: null,
  updateFailure: null,
  reset: null,
}, {
  prefix: "@Profile/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false
}

const updateRequest = (state = INITIAL_STATE) => ({
  ...state,
  success: false,
  loading: true,
  error: false
})

const updateSuccess = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: true,
  error: false
})

const updateFailure = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: true
})

const reset = (state = INITIAL_STATE) => ({
  ...state,
  loading: false,
  success: false,
  error: false
})

export default createReducer(INITIAL_STATE, {
  [Types.UPDATE_REQUEST]: updateRequest,
  [Types.UPDATE_SUCCESS]: updateSuccess,
  [Types.UPDATE_FAILURE]: updateFailure,
  [Types.RESET]: reset,
})