import Sdk from 'api.digitalpages.module.sdk.api';
import { put } from 'redux-saga/effects';

import { Creators as AuthActions } from '~/store/ducks/auth'; 

export function* signin(action) {
  try {
    const { login, password, voucher } = action.payload || {};
    yield Sdk.Api.authorization.signinByLogin(login, password);

    if (voucher) {
      yield Sdk.Api.store.purchaseVoucher(voucher);
    }

    yield put(AuthActions.loginSuccess());
  } catch (err) {
    yield put(AuthActions.loginFailure());
  }
}

export function* logout() {
  try {
    yield Sdk.Api.authorization.logout();
    yield put(AuthActions.logoutSuccess());
  } catch (err) {}
}
