import Sdk from 'api.digitalpages.module.sdk.api';

async function details({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const response = await Sdk.dynamic.bridge(`auth/v1.0/entity/uid/${payload.entity}`);

  onChangeState({
    loading: false,
    response
  });
}
async function getSchemaEntities ({ payload, onChangeState }) {
  function mountFilters () {
    return new Promise(async (resolve, reject) => {
      const categorySchemas = await Sdk.dynamic.bridge('auth/v1.0/entity/schema/available?type=category')
      const filters = []
      if (!categorySchemas) return onChangeState({success: false})
      categorySchemas.forEach(async category => {
        const schemaItems = await Sdk.dynamic.bridge(`auth/v1.0/entity/schema/uid/${category.uid}/items`)
        console.log({schemaItems})
        filters.push({
          uid: category.uid,
          label: category.name,
          children: schemaItems.map(item => ({
            uid: item.uid,
            label: item.data['Nome']
          }))
        })
      })
      resolve(filters)
    })
  }
  // onChangeState({loading: true})
  const filters = await mountFilters()

  // const filters = categorySchemas.map(category => ({
  //   uid: category.uid,
  //   label: category.name,
  //   children: [
  //     {
  //       "uid": "123",
  //       "label": "Categoria 1"
  //     },
  //     {
  //       "uid": "456",
  //       "label": "Categoria 2"
  //     },
  //     {
  //       "uid": "789",
  //       "label": "Categoria 3"
  //     }
  //   ]
  // }))
  return onChangeState({
    success: true,
    filters
  })
  // const entities = []
  // forEach(async schemaId => {
  //   const schemaEntities = await Sdk.Api.authorization.entity.schemaItems(schemaId)
  //   console.log({schemaEntities})
  //   // entities.push({

  //   // })
  // });
  // {
  //   "uid": "abc",
  //   "label": "Categorias",
    // "children": [
    //   {
    //     "uid": "123",
    //     "label": "Categoria 1"
    //   },
    //   {
    //     "uid": "456",
    //     "label": "Categoria 2"
    //   },
    //   {
    //     "uid": "789",
    //     "label": "Categoria 3"
    //   }
    // ]
  // },

}

export default {
  details,
  getSchemaEntities
}