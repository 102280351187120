
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Sdk from 'api.digitalpages.module.sdk.api';

export function useAuth() {
  const [logged, setLogged] = useState(Sdk.Api && Sdk.Api.authorization.authenticated);

  const auth = useSelector(state => state.auth);

  useEffect(() => Sdk.Api && setLogged(Sdk.Api.authorization.authenticated), [auth, auth.loading])

  return [logged, setLogged];
}