import { put } from 'redux-saga/effects';
import { Creators as RacingActions } from '~/store/ducks/racing'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* getRacing(action) {
  try {
    const { 
      routeUid, 
      pageSize,
      season
    } = action.payload || {};

    const data = yield Sdk.Api.news.getNoticesByRoute(routeUid, {
      pageSize,
      order_field: "event_at",
      order_type: "desc",
      filter: `(season:${season.selected})`,
    });

    yield put(RacingActions.getSuccess(data));
  } catch (err) {}
}
