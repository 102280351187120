import { createActions } from 'reduxsauce'

export const configDuckList = (prefix, apiOptions = {}, extraActions = {}) => {
  return {
    ...createActions({
      getRequest: ['payload'],
      getSuccess: ['data'],
      ...extraActions
    }, {
      prefix: `${prefix}/`
    }),
    Connector: apiOptions.Connector,
    Route: apiOptions.Route
  }
}
