import Sdk, {
  Activity,
  XApiInteraction,
  XApiPredefinedVerbs
} from 'api.digitalpages.module.sdk.api';

import { fromString } from 'uuidv4';

class xApiBuilder {
  constructor() {
    this.topicData = {};
    /**
     * XApiEvents mappeds by action element(click, view)
     */
    this.mapInteractions = {
      'coursefile': {
        'downloaded': {
          'attachments': elementData => {
            if (elementData.metadata && elementData.metadata.download_info) {
              return [
                {
                  "contentType": elementData.metadata.download_info.contentType,
                  "usageType": "http://id.tincanapi.com/attachment/certificate-of-completion",
                  // "content": elementData.metadata.download_info.content,
                  "display": {
                    "en-US": elementData.metadata.element_title
                  },
                  "description": {
                    "en-US": elementData.metadata.element_title
                  },
                  "length": elementData.metadata.download_info.length,
                  "sha2": elementData.metadata.download_info.sha2
                }
              ]
            }
            return []
          },
          'interaction': elementData => {
            const {
              metadata: { element_title, element_id }
            } = elementData;

            return this._baseInteractionElement('downloaded', {
              elementData: elementData,
              idType: 'id',
              id: element_id,
              friendlyName: element_title,
              definitionType: 'http://adlnet.gov/expapi/activities/interaction'
            })
          }
        }
      },
      'course': {
        'advanced': {
          'interaction': elementData => {
            const {
              metadata: { element_title, element_id }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'id',
              id: element_id,
              friendlyName: element_title,
              definitionType: 'http://id.tincanapi.com/activitytype/step'
            })
          },
          'result': data => {
            const {
              metadata: { progress, completed }
            } = data;

            return {
              completion: completed,
              extensions: {
                'https://w3id.org/xapi/cmi5/result/extensions/progress': progress
              }
            }
          }
        }
      },
      'topic': {
        'start': {
          'options': {
            'ignoreActivity': ['topic']
          },
          'interaction': elementData => {
            const {
              metadata: { element_title, element_id }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'id',
              id: element_id,
              friendlyName: element_title,
              definitionType: 'http://id.tincanapi.com/activitytype/step'
            })
          }
        },
        'finish': {
          'options': {
            'ignoreActivity': ['topic']
          },
          'interaction': elementData => {
            const {
              metadata: { element_title, element_id, completed }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: Object.assign(elementData, { verb: completed ? 'completed' : 'closed' }),
              idType: 'id',
              id: element_id,
              friendlyName: element_title,
              definitionType: 'http://id.tincanapi.com/activitytype/step'
            })
          },
          'result': data => {
            const {
              metadata: { completed, duration }
            } = data;

            return {
              completion: completed,
              duration: duration
            }
          }
        }
      },
      'accordion': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { active_accordion_title, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(active_accordion_title),
              friendlyName: friendlyName || active_accordion_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        },
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_title, element_id, friendlyName }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        }
      },
      'text': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/paragraph')
          // }
        }
      },
      'video': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'https://w3id.org/xapi/acrossx/activities/video')
          // }
        }
      },
      'tabs': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { active_tab_title, element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(active_tab_title),
              friendlyName: friendlyName || active_tab_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        },
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        }
      },
      'flashcards': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, side, position, friendlyName }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || `Flash ${position}, ${element_title}`
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        },
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName },
            } = elementData;

            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/collection-simple')
          }
        }
      },
      'image': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/resource')
          // }
        },
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            if (elementData.metadata.carrosel_title) {
              return this._baseActivity({
                element: 'carousel',
                metadata: {
                  element_title: elementData.metadata.carrosel_title
                }
              }, null, 'http://id.tincanapi.com/activitytype/resource')
            }
            return false
          }
        }
      },
      'hamburguer': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/resource')
          // }
        }
      },
      'menu': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('interacted', {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/resource')
          // }
        }
      },
      'quiz-only-choice-question-navigation-next': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_id, question, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement('advanced', {
              elementData: elementData,
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(question.element_title),
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return [
              this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
            ]
          }
        }
      },
      'quiz-only-choice-question-navigation-previous': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_id, question, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`retreated`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return [
              this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
            ]
          }
        }
      },
      'quiz-only-choice-question-answer': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { question, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`clicked-answered`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: question.id || this._getUuidByTitle(question.title + question.answer.title),
              friendlyName: friendlyName || question.answer.title
            })
          },
          'activity': elementData => {
            return [
              this._baseActivity(elementData, null, 'http//adlnet.gov/expapi/activities/assessment'),
              this._baseActivityQuestion(elementData, 'http://adlnet.gov/expapi/activities/question')
            ]
          }
        }
      },
      'quiz-only-choice-question-confirm-answer': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { question, friendlyName, element_title }
            } = elementData;

            let interaction = {}

            if (question.answers) {
              try {
                interaction = {
                  interactionType: 'choice',
                  interactionMetadataLabel: ['choices'],
                  interactionMetadata: [question.answers.map(answer => ({
                    id: this._getUuidByTitle(answer.content + element_title),
                    description: {
                      'pt-br': answer.content
                    }
                  }))],
                  correctResponsesPattern: [this._getUuidByTitle(question.answers.find(_answer => _answer.correct).content + element_title)]
                }
              } catch { }
            }

            return this._baseInteractionElement(`confirm-answered`, {
              elementData: elementData,
              idType: 'uuid',
              ...interaction,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: question.id || this._getUuidByTitle(question.element_title),
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          },
          'result': elementData => {
            const {
              metadata: { question, element_title }
            } = elementData;
            return {
              response: this._getUuidByTitle(question.answer.title + element_title),
              completion: true,
              success: question.answer.correct
            }
          }
        }
      },
      'quiz-multiple-choice-question-answer': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { question, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`clicked-answered`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: question.id || this._getUuidByTitle(question.title + question.answer.title),
              friendlyName: friendlyName || question.answer.title
            })
          },
          'activity': elementData => {
            return [
              this._baseActivity(elementData, null, 'http//adlnet.gov/expapi/activities/assessment'),
              this._baseActivityQuestion(elementData, 'http://adlnet.gov/expapi/activities/question')
            ]
          }
        }
      },
      'quiz-multiple-choice-question-confirm-answer': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { question, friendlyName }
            } = elementData;
            let interaction = {}

            if (question.answers) {
              try {
                interaction = {
                  interactionType: 'choice',
                  interactionMetadataLabel: ['choices'],
                  interactionMetadata: [question.answers.map(answer => ({
                    id: answer.id,
                    description: {
                      'pt-br': answer.title || answer.text.replace(/<\/?[^>]+(>|$)/g, "")
                    }
                  }))],
                  correctResponsesPattern: [question.answers.map(_answer => _answer.correct ? _answer.id : undefined).filter(_answer => Boolean(_answer)).join('[,]')]
                }
              } catch { }
            }

            return this._baseInteractionElement(`confirm-answered`, {
              elementData: elementData,
              idType: 'uuid',
              ...interaction,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: question.id || this._getUuidByTitle(question.element_title),
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          },
          'result': elementData => {
            const {
              metadata: { question, element_title }
            } = elementData;

            let correctAnswer = true;
            let totalCorrects = 0;
            question.answers.map(choice => {
              if (choice.correct) totalCorrects++;
              if (!choice.correct && question.answer && question.answer.includes(choice.id)) {
                correctAnswer = false;
              }
            });
            if (!question.answer || totalCorrects !== question.answer.length) correctAnswer = false;

            return {
              response: question.answer.map(answer => answer.id).join('[,]'),
              completion: true,
              success: correctAnswer
            }
          }
        }
      },
      'quiz-matching-question-confirm': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, source, target, correctResponsesPattern }
            } = elementData;

            let interaction = {
              interactionType: 'matching',
              interactionMetadataLabel: ['source', 'target'],
              interactionMetadata: [
                source.map(item => ({
                  id: item,
                  description: {
                    'pt-br': item
                  }
                })),
                target.map(item => ({
                  id: item,
                  description: {
                    'pt-br': item
                  }
                }))
              ],
              correctResponsesPattern
            }

            return this._baseInteractionElement(`confirm-answered`, {
              elementData: elementData,
              idType: 'uuid',
              ...interaction,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: this._getUuidByTitle(element_title),
              friendlyName: element_title,
              description: element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // },
          'result': elementData => {
            const {
              metadata: { score, result }
            } = elementData;
            return {
              response: result,
              completion: true,
              score
              // success: question.answer.correct
            }
          }
        }
      },
      'quiz-only-choice-question': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, question, friendlyName, element_title, location }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              idType: 'uuid',
              id: question.id || this._getUuidByTitle(question.element_title),
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          }
        }
      },
      'quiz-multiple-choice-question': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, question, friendlyName, element_title, location }
            } = elementData;

            return this._baseInteractionElement('viewed', {
              elementData: elementData,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              idType: 'uuid',
              id: question.id || this._getUuidByTitle(question.element_title),
              friendlyName: friendlyName || question.title,
              description: question.location || question.title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          }
        }
      },
      'quiz-only-choice-question-result': {
        'result': {
          'interaction': elementData => {
            const {
              metadata: { question = {}, friendlyName }
            } = elementData;
            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              id: question.id || this._getUuidByTitle(question.element_title),
              friendlyName: friendlyName || (question.answer.hit ? 'Correto!' : 'Incorreto!')
            })
          },
          'activity': elementData => {
            return [
              this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment'),
              this._baseActivityQuestion(elementData, 'http://adlnet.gov/expapi/activities/question')
            ]
          }
        }
      },
      'quiz-only-choice': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          }
        }
      },
      'quiz-multiple-choice': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              definitionType: 'http://adlnet.gov/expapi/activities/question',
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          }
        }
      },
      'quiz-only-choice-result': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName, result }
            } = elementData;


            let success = undefined;

            if (result) {
              const raw = Number(result.percentage_average.replace('%', ''));
              const min = Number(result.min_percentage ? result.min_percentage.replace('%', '') : 0);

              success = raw >= min
            }

            return this._baseInteractionElement(`viewed`, {
              elementData: Object.assign(elementData, {
                verb: success == undefined ? elementData.verb : success == false ? 'failed' : 'passed'
              }),
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              definitionType: 'http://adlnet.gov/expapi/activities/assessment',
              friendlyName: friendlyName || `Resultado do Quiz "${element_title}"`
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // },
          'result': elementData => {
            const {
              metadata: { result }
            } = elementData;

            let finalResult = {
              completion: false,
              score: null
            }

            if (result) {
              const raw = Number(result.percentage_average.replace('%', ''));
              const min = Number(result.min_percentage ? result.min_percentage.replace('%', '') : 0);

              finalResult = {
                completion: true,
                score: {
                  raw,
                  min,
                  max: 100
                },
                success: raw >= min
              }
            }

            return finalResult
          }
        }
      },
      'quiz-multiple-choice-result': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName, result }
            } = elementData;


            let success = undefined;

            if (result) {
              const raw = Number(result.percentage_average ? String(result.percentage_average).replace('%', '') : 100);
              const min = Number(result.min_percentage ? String(result.min_percentage).replace('%', '') : 0);

              success = raw >= min
            }

            return this._baseInteractionElement(`viewed`, {
              elementData: Object.assign(elementData, {
                verb: success == undefined ? elementData.verb : success == false ? 'failed' : 'passed'
              }),
              idType: 'uuid',
              id: element_id || this._getUuidByTitle(element_title),
              definitionType: 'http://adlnet.gov/expapi/activities/assessment',
              friendlyName: friendlyName || `Resultado do Quiz "${element_title}"`
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // },
          'result': elementData => {
            const {
              metadata: { result }
            } = elementData;

            let finalResult = {
              completion: false,
              score: null
            }

            if (result) {
              const raw = Number(result.percentage_average ? String(result.percentage_average).replace('%', '') : 0);
              const min = Number(result.min_percentage ? result.min_percentage.replace('%', '') : 0);

              finalResult = {
                completion: true,
                score: {
                  raw,
                  min,
                  max: 100
                },
                success: raw >= min
              }
            }

            return finalResult
          }
        }
      },
      'header-1': {
        'view': {
          'interaction': elementData => {
            const {
              metadata: { element_id, element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`viewed`, {
              elementData: elementData,
              idType: 'uuid',
              id: element_id,
              friendlyName: friendlyName || element_title
            })
          },
          'activity': elementData => {
            return this._baseActivity(elementData, null, 'http://id.tincanapi.com/activitytype/resource')
          }
        }
      },
      'hotspot': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`interacted`, {
              elementData: elementData,
              idType: 'uuid',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/interaction')
          // }
        }
      },
      'quiz-only-choice-questionnaire-init': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`started`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/assessment',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // }
        }
      },
      'quiz-only-choice-questionnaire-restart': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`started`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/assessment',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // }
        }
      },
      'quiz-matching-question-restart': {
        'click': {
          'interaction': elementData => {
            const {
              metadata: { element_title, friendlyName }
            } = elementData;

            return this._baseInteractionElement(`started`, {
              elementData: elementData,
              idType: 'uuid',
              definitionType: 'http://adlnet.gov/expapi/activities/assessment',
              id: this._getUuidByTitle(element_title),
              friendlyName: friendlyName || element_title
            })
          },
          // 'activity': elementData => {
          //   return this._baseActivity(elementData, null, 'http://adlnet.gov/expapi/activities/assessment')
          // }
        }
      }
    }
  }

  _getUuidByTitle(title) {
    return fromString(title + (this.topicData ? this.topicData.id : this.courseData.id))
  }

  /**
   * Get a interaction by element
   *
   * @param {*} verb
   * @param {*} param1
   */
  _baseInteractionElement(verb, {
    interactionMetadataLabel,
    interactionMetadata,
    interactionType,
    idType,
    id,
    friendlyName,
    elementData,
    definitionType,
    result,
    description,
    correctResponsesPattern
  }) {
    return new XApiInteraction({
      interactionMetadataLabel,
      correctResponsesPattern,
      interactionMetadata,
      interactionType,
      name: friendlyName,
      description: description || friendlyName || "",
      idType,
      projectKey: Sdk.insights.api.authorization.projectKey,
      action: 'reference',
      id: idType == 'name' ? encodeURI(id) : id,
      elementName: elementData.element,
      language: 'pt-br',
      definitionType,
      result
    })
  }

  /**
   * Return a new XApi Activity
   *
   * @param {*} elementData
   * @param {*} type
   * @param {*} action
   * @param {*} description
   */
  _baseActivity(elementData, description, definition, idType) {
    return new Activity(
      Sdk.insights.api.authorization.projectKey,
      elementData.element,
      'reference',
      idType || 'uuid',
      elementData.metadata.element_id || this._getUuidByTitle(elementData.metadata.element_title),
      'pt-br',
      elementData.metadata.friendlyName || elementData.metadata.element_title,
      description || "",
      definition,
    )
  }

  /**
   * Return a new XApi Activity by Question
   *
   * @param {*} elementData
   * @param {*} type
   * @param {*} action
   * @param {*} description
   */
  _baseActivityQuestion(elementData, definition) {
    let question = {}
    if (elementData.metadata) {
      question = elementData.metadata.question
    }

    return new Activity(
      Sdk.insights.api.authorization.projectKey,
      'question',
      'reference',
      'uuid',
      this._getUuidByTitle(question.element_title || question.title),
      'pt-br',
      question.title,
      question.location || question.title || "",
      definition,
    )
  }
  // elementData: elementData,
  // idType: 'uuid',
  // id: element_id || this._getUuidByTitle(question.element_title),
  // definitionType: 'http://adlnet.gov/expapi/activities/question',
  // friendlyName: friendlyName || question.title,
  // description: question.location || question.title


  /**
   * Example ID: https://api.digitalpages.com.br/key/5287762a6ecf44d5ac58592ccf9a921f/course/uid/5fb1f0c2-6b24-40e8-9e86-79258989b0d5
   *
   * @param {Object} param
   */
  _getActivityCourse({
    project = Sdk.insights.api.authorization.projectKey,
    title,
    id,
    verb = 'reference',
    idType = 'id',
    description,
    location = 'course',
    language = 'pt-br',
    definition = 'http://adlnet.gov/expapi/activities/course'
  }) {
    return new Activity(
      project,
      location,
      verb,
      idType,
      id,
      language,
      title,
      description || "",
      definition,
    );
  }

  /**
   * Example ID: https://api.digitalpages.com.br/key/5287762a6ecf44d5ac58592ccf9a921f/lesson/uid/1f3517ea-d1d4-43f7-91bd-1738c44d9254
   *
   * @param {Object} param
   */
  _getActivityTopic({
    project = Sdk.insights.api.authorization.projectKey,
    title,
    idType = 'id',
    id,
    verb = 'reference',
    location = 'topic',
    language = 'pt-br',
    definition = 'http://adlnet.gov/expapi/activities/lesson'
  }) {
    return new Activity(
      project,
      location,
      verb,
      idType,
      id,
      language,
      title,
      "",
      definition
    );
  }

  _getVerb(elementData) {
    if (elementData.verb) {
      return XApiPredefinedVerbs[elementData.verb];
    }

    if (["text", "video", "image"].includes(elementData.element)) {
      return XApiPredefinedVerbs.viewed
    }
  }

  /**
   * @description
      ElementData examples:

        action: "view"
        element: "text"
        metadata:
          element_id: "cbcdd370-e86b-11ea-b9a6-613b07a12111"
          element_title: "Unidade 1 - 1.1 - Tensoativos naturais e sintéticos."
        timestamp: 1601781392

        action: "click"
        element: "accordion"
        metadata:
          active_accordion_title: "Mas, para início de conversa, você sabe o que é saponificação?"
          element_id: "3b6cf560-f2aa-11ea-8dbf-633fd221ccad"
          element_title: "Unidade 1 - O que é saponificação?"
        timestamp: 1601781440
        verb: "opened"

   *
   * @param {*} elementData
   * @param {*} course
   * @param {*} topic
   */
  send(elementData, course, topic) {
    try {
      this.topicData = topic;
      this.courseData = course;

      const mappedInteraction = this.mapInteractions[elementData.element];

      if (mappedInteraction) {
        const courseActivity = this._getActivityCourse({
          title: course.title,
          id: course.id,
          description: course.description
        });

        let topicActivity = null

        if (topic) {
          topicActivity = this._getActivityTopic({
            title: topic.title,
            id: topic.id,
          });
        }

        if (mappedInteraction[elementData.action] == undefined) {
          throw new Error(`[info]: element action was not mapped, data: ${JSON.stringify(elementData)}`)
        }

        const elementInteraction = mappedInteraction[elementData.action].interaction(elementData);
        const fnElementResult = mappedInteraction[elementData.action].result || (() => null);
        const fnElementAttach = mappedInteraction[elementData.action].attachments || (() => null);

        let elementActivity = mappedInteraction[elementData.action].activity;
        if (elementActivity) {
          elementActivity = elementActivity(elementData)
          elementActivity = Array.isArray(elementActivity) ? elementActivity : [elementActivity]
        } else {
          elementActivity = []
        }

        const interactionOptions = mappedInteraction[elementData.action].options
        if (interactionOptions && interactionOptions.ignoreActivity) {
          const { ignoreActivity } = interactionOptions

          if (ignoreActivity.some(op => op == 'topic')) {
            topicActivity = null
          }
        }

        let verb = this._getVerb(elementData);

        Sdk.insights.xapi.sendScormInteraction(
          elementInteraction, [
            courseActivity,
            topicActivity,
            ...elementActivity
          ].filter(e => e),
          fnElementResult(elementData),
          verb,
          new Date().toISOString(),
          fnElementAttach(elementData)
        );

      } else {
        console.warn(`[info]: element was not tracked, data: ${JSON.stringify(elementData)}`);
      }
    } catch (e) {
      console.warn('[error]: ', e);
    }
  }
}

export default new xApiBuilder();