import { createActions , createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  getSuccess: ['data'],
  getRequest: ['data']
}, {
  prefix: "@UserPurchases/"
})

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: false,
  data: []
}

const getPurchasesSuccess = (state = INITIAL_STATE, { data }) => ({
  ...state,
  data,
  loading: false,
  success: true,
})

const getPurchasesRequest = (state = INITIAL_STATE) => ({
  ...state,
  loading: true,
  success: false
})

export default createReducer(INITIAL_STATE, {
  [Types.GET_SUCCESS]: getPurchasesSuccess,
  [Types.GET_REQUEST]: getPurchasesRequest,
})