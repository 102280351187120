import Sdk from 'api.digitalpages.module.sdk.api';
import { v4 as uuidv4 } from 'uuid';

async function sendLink({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { interactionUid, url } = payload;
  if (!url) {
    onChangeState({
      loading: false
    });
  }

  const response = await Sdk.dynamic.bridge(`cms/v1.0/learning/interaction/uid/${interactionUid}/content/user?url=${url}`, null, 'POST');

  onChangeState({
    loading: false,
    response
  });
}

async function getCodes({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { files, submissionUid } = payload;

  var sources = files;
  var contents = await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${submissionUid}/contents`);
        
  for (var i = 0; i < sources.length; i++) {
    sources[i].language = sources[i].mime_type !== null ? sources[i].mime_type.split("/")[1] : "auto";
    sources[i].name = sources[i].uri;
    sources[i].url = `https://api.digitalpages.com.br/storage/${Sdk.Api.version}/content?uid=${contents.target_content_uid}&file_path=${sources[i].uri}&project_key=${Sdk.Api.projectKey}&access_token=${Sdk.Api.authorization_token}&api_env=${Sdk.Api.enviroment}`
    // await fetch(sources[i].url).then(resp => resp.text()).then(content => sources[i].value = content)
  };

  onChangeState({
    loading: false,
    sources
  });
}

async function codeReviewSetCodes({ payload, onChangeState }) {
  // onChangeState({
  //   loading: true
  // });

  // const { files, submissionUid } = payload;

  // var sources = files;
  // var contents = await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${submissionUid}/contents`);
        
  // for (var i = 0; i < sources.length; i++) {
  //   sources[i].language = sources[i].mime_type !== null ? sources[i].mime_type.split("/")[1] : "auto";
  //   sources[i].name = sources[i].uri;
  //   sources[i].url = `https://api.digitalpages.com.br/storage/${Sdk.Api.version}/content?uid=${contents.target_content_uid}&file_path=${sources[i].uri}&project_key=${Sdk.Api.projectKey}&access_token=${Sdk.Api.authorization_token}&api_env=${Sdk.Api.enviroment}`
  //   await fetch(sources[i].url).then(resp => resp.text()).then(content => sources[i].value = content)
  // };

  // onChangeState({
  //   loading: false,
  //   sources
  // });
}

async function getRubric({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { nodes, activityUid } = payload;
  
  const node = nodes.find(node => node.activities.some(activity => activity.uid === activityUid));
  if (!node) {
    onChangeState({
      loading: false
    });
    return;
  }

  const activity = node.activities.find(activity => activity.uid === activityUid);
  const response = await Sdk.dynamic.bridge(`cms/v1.0/review/flow/uid/${activity.review_flows[0]}`)
  
  onChangeState({
    loading: false,
    response
  });
}

async function getHistory({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { nodes, interactionUid, activityUid } = payload;
  
  const node = nodes.find(node => node.activities.some(activity => activity.uid === activityUid));
  if (!node) {
    onChangeState({
      loading: false
    });
    return;
  }

  const activity = node.activities.find(activity => activity.uid === activityUid);

  let response = await Sdk.dynamic.bridge(`cms/v1.0/review/flow/uid/${activity.review_flows[0]}/target/type/interaction/uid/${interactionUid}/history`)
  response = await Promise.all(
    response.map(async submission => {

      //primeira versão
      if (submission.target && submission.target.content_uid)
      {
        submission.manifest = await Sdk.dynamic.bridge(`storage/v1.0/content/path/manifest.json?uid=${contents.target.content_uid}`);
      }
      else
      {
        var contents = await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${submission.uid}/contents`);
        
        if (contents.target_content_uid) {
          submission.manifest = await Sdk.dynamic.bridge(`storage/v1.0/content/path/manifest.json?uid=${contents.target_content_uid}`);
        }
      }

      return submission
    })
  );

  onChangeState({
    loading: false,
    response
  });  
}

async function saveReview({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { submission, general_comments, rubrics, code_review } = payload;

  var obj = {
    "evaluations": [],
    "general_messages": [{
      "message" : general_comments
    }]
  }

  for (var i = 0; i < rubrics.length; i++) {
    var data = {
      "rubric": {
        "uid": rubrics[i].uid
      },
      "messages": [{
        "message" : rubrics[i].comment
      }]
    }

    if (rubrics[i].active_sub_item_uid) {
      data.criterion = {
        "uid": rubrics[i].active_sub_item_uid
      };
    };

    obj.evaluations.push(data);
  }

  const response = await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${submission.uid}/draft`, obj, 'PUT');
  // var reviewer_manifest = submission.reviewer_manifest;
  // reviewer_manifest.comments = code_review;


  var jsonse = JSON.stringify(code_review);
  var blob = new Blob([jsonse], {type: "application/json"});

  const formData  = new FormData();
  formData.append("manifest.json", blob);

  var url = `https://api.digitalpages.com.br/storage/v1.0/content/path/manifest.json?uid=${submission.review_content_uid}`

  const xpto = await fetch(url, {
    method: 'PUT',
    body: formData,
    headers: {
      "Project-Key": Sdk.Api.projectKey,
      "Api-Env": Sdk.Api.enviroment,
      "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`
    }
  });

  onChangeState({
    loading: false,
    response
  });
}


async function submitReview({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  const { submission } = payload;
  const response = await Sdk.dynamic.bridge(`cms/v1.0/review/task/uid/${submission.uid}/close`, null, 'PUT');

  onChangeState({
    loading: false,
    response
  });
}

async function sendFile({ payload, onChangeState }) {
  onChangeState({
    loading: true
  });

  // "comments": [
  //   {
  //       "asset_uid": "00b13aab-13d0-4872-98be-f03637817bb0",
  //       "type": "file",
  //       "content" :{
  //           "source_uid" : "07d87ab0-94ca-49c2-b552-d15d55463e52"
  //       }
  //   }
  // ],
  // "assets": [
  //   {
  //       "uid": "00b13aab-13d0-4872-98be-f03637817bb0",
  //       "name": "sources/blob",
  //       "mime_type": "application/pdf",
  //       "uri": "https://api.digitalpages.com.br/storage/v1.0/content/path/sources/blob?uid=H4sIAAAAAAAACgBiAZ3-AwAAAUgAAAAAAGM3MDU2YTYwPQUisoXXxdRk8UVH79am6icWYkkVOuMubX_g1NW7MbhUXvYrrUIP-dBjG9RhEXgRO2nJ6ywM1_EoERX2iV7F_hk4qAQSGbHSqm4Gw6qy9ix3jVS8XY8dmBfDlx8XuWp4lXt98xtxwTGTg1PhaQI-ivMR8bDelo4YvoUsjsnjTIzcL1ZUocsxoWL5i16YY1Mj0YvOMrDyPnOuLN3qsuDmsHy61zWzgwue1mHdMdb7ATGsQkBQSkVNzjGJ4fZoxz7VZp4HJzL0NNqMqPK8Wzbn4KRbTnkmSZHEz_ZvZ0iKLvv0Gdmlr7vUgAn9QlbvGryNY4Fwi49tTBpkjX0HT_rKXbG7AmgQzfln5vTKyw0XMe33xSgfe0j33MyyO1S6ZqdM1UUfRhXkTN6aqTjiYVJmmiVKmywH8qxJwNgoUcoOFjOIytR1eISLEIfERLh17SkKAAAA__8",
  //       "type": "relative"
  //   }
  // ]
  // "sources": [
  //   {
  //       "uid": "07d87ab0-94ca-49c2-b552-d15d55463e52",
  //       "uri": "arquivo_revisado.pdf",
  //       "mime_type": "application/pdf",
  //       "type": "Relative"
  //   }
  // ],

  const { viewerFile, submissionData, file } = payload;
  const fileUid = viewerFile.uid;


  var blob = new Blob([file], {type: "application/pdf"});
  const formData  = new FormData();
  const fileName = `${fileUid}.pdf`;
  formData.append(fileName, blob, fileName);


  var url = `https://api.digitalpages.com.br/storage/v1.0/content/path/${fileName}?uid=${submissionData.review_content_uid}`

  const upload = await fetch(url, {
    method: 'PUT',
    body: formData,
    headers: {
      "Project-Key": Sdk.Api.projectKey,
      "Api-Env": Sdk.Api.enviroment,
      "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`
    }
  });

  var manifest = submissionData.reviewer_manifest;

  if (!manifest.sources) manifest.sources = [];
  var source = manifest.sources.find(p => p.uri.indexOf(fileName) !== - 1);
  if (!source) {
    source = {
      "uid": uuidv4(),
      "uri": fileName,
      "mime_type": "application/pdf",
      "type": "relative",
      "display_name": file.name,
      "display_size": file.size
    }
    manifest.sources.push(source);
  }

  if (!manifest.assets) manifest.assets = [];
  if (!manifest.assets.find(p => p.uid === fileUid)) {
    manifest.assets.push({
      "uid": fileUid,
      "name": fileName,
      "mime_type": "application/pdf",
      "uri": `https://api.digitalpages.com.br/storage/v1.0/content/path/${viewerFile.uri}?uid=${submissionData.target_content_uid}`,
      "type": "internal"
    })
  }

  if (!manifest.comments) manifest.comments = [];
  if (!manifest.comments.find(p => p.asset_uid === fileUid && p.content.source_uid === source.uid)) {
    manifest.comments.push({
      "asset_uid": fileUid,
      "type": "file",
      "content": {
        "source_uid": source.uid
      }
    })
  }

  // manifest.sources = [];
  // manifest.assets = [];
  // manifest.comments = [];

  var jsonse = JSON.stringify(manifest);
  var blob = new Blob([jsonse], {type: "application/json"});

  const manifestFormData  = new FormData();
  manifestFormData.append("manifest.json", blob);

  var url = `${Sdk.dam.domain}/storage/v1.0/content/path/manifest.json?uid=${submissionData.review_content_uid}`

  const response = await fetch(url, {
    method: 'PUT',
    body: manifestFormData,
    headers: {
      "Project-Key": Sdk.Api.projectKey,
      "Api-Env": Sdk.Api.enviroment,
      "Authorization": `Bearer ${Sdk.Api.authorization.credential.accessToken}`
    }
  });

  onChangeState({
    loading: false,
    response
  });
}

export default {
  getCodes,
  getRubric,
  getHistory,
  sendLink,
  codeReviewSetCodes,
  saveReview,
  submitReview,
  sendFile
}