import { put } from 'redux-saga/effects';

import { Creators as UserActions } from '~/store/ducks/user'; 
import Sdk from 'api.digitalpages.module.sdk.api';

export function* register(action) {
  try {
    const {
      name: first_name,
      lastName: last_name,
      login: email_principal,
      cpf,
      birthday,
      region,
      login,
      password
    } = action.payload || {};

    const body = { 
      detail: {
        name: `${first_name} ${last_name}`,
        email_principal, 
        first_name, 
        last_name,
        cpf,
        birthday,
        region
      },
      login, 
      password, 
    };

    const response = yield Sdk.Api.directRegister.register(body);

    if (response.error === 'Erro') {
      yield put(UserActions.registerFailure(response.detail));
    } else {
      yield put(UserActions.registerSuccess());
    }
  } catch (err) {
    yield put(UserActions.registerFailure());
  }
}
